import React, { useEffect, useState } from "react";
import { Spinner } from "../ui/Spinner";
import dishImage from "../../assets/images/dish.png";
import { useApi } from "../../customHooks/useApi";

export const Categories = ({ fetchItems }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { data } = useApi(`/Cashier/getSubCategories/?pageSize=100`);

  // console.log(categoriesResponse);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  // useEffect(() => {
  //   async function getCategories() {
  //     try {
  //       const subCategories = [];

  //       if (token) {
  //         const categoriesResponse = await axiosAuthInstance.get(
  //           `/TblItemCategory/getPage/?pageSize=100`
  //         );

  //         const fetchedCategories = categoriesResponse.data.rs.data;
  //         fetchedCategories.forEach((item) => {
  //           if (item.fkCategory) {
  //             subCategories.push(item);
  //           }
  //         });

  //         console.log(categoriesResponse.data.rs.data);
  //         setCategories(subCategories);
  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.log(error);
  //     }
  //   }

  //   getCategories();
  // }, []);

  return (
    <div>
      <div className="categories-wrapper">
        <div className="categories-container">
          {isLoading ? <Spinner /> : ""}
          {data
            ? data.map((item) => (
                <div
                  className="category"
                  onClick={() => {
                    fetchItems(item.PK_Category);
                  }}
                  key={item.PK_Category}
                >
                  <img
                    src={item.CatImage ?? dishImage}
                    className="categoryimage"
                    alt="dish"
                  />
                  <span className="categoryname">{item.CategoryName}</span>
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

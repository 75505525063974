import React from "react";
import { Header } from "./main/Header";
import { Sidebar } from "./main/Sidebar";
// import ThemeContext from "../shop/ThemeContext";
// import { Switcher } from "../components/main/Switcher";

export const Main = (props) => {
  // const ctx = useContext(ThemeContext);
  // const storageTheme = localStorage.getItem("theme");

  return (
    <div className={`app`}>
      <div className="wrapper">
        <Header />
        <div className="page-wrapper">
          <Sidebar />
          <div className="page-content">
            <main>{props.children}</main>
          </div>
        </div>
      </div>
      {/* <Switcher /> */}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { act } from "react-dom/test-utils";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const SidebarItem = ({
  hasArrow,
  path,
  title,
  icon,
  hash,
  children,
  to,
}) => {
  const location = useLocation();
  console.log(location.pathname);
  // const [active, setActive] = useState(false);
  const active = location.pathname === to ? "arrow-right" : "";
  // console.log(to);
  // useEffect(() => {
  //   console.log(active);
  // }, [active]);

  // const clickMe = (e) => {
  //   setActive(!active);
  //   // const sidebarLinks = document.querySelectorAll(".sidebar-link");

  //   // sidebarLinks.forEach((item) => {
  //   //   console.log("item");
  //   //   item.classList.remove("arrow-right");
  //   // });
  //   // const targetItem = e.target.closest(".sidebar-link");
  //   // targetItem.classList.add("arrow-right");
  //   // console.log(targetItem);
  // };

  return (
    <li
      className={`sidebar-link ${active ? "arrow-right" : ""}`}
      // onClick={(e) => {
      //   // setActive(!active);
      //   clickMe(e);
      // }}
    >
      {/* <div className="arrow-right"></div> */}
      {path ? (
        <Link
          // onClick={() => setActive(!active)}
          to={path}
          className={hasArrow ? "has-arrow" : ""}
        >
          {/* <div className="parent-icon">
            <i className={icon}></i>
          </div> */}
          <span className="menu-title">{title}</span>
        </Link>
      ) : (
        <a href={hash} className={`${hasArrow ? "has-arrow " : ""} `}>
          <div className="parent-icon">
            <i className={icon}></i>
          </div>
          <div className="menu-title">{title}</div>
        </a>
      )}
      {children}
    </li>
  );
};

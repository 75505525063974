import React, { useState } from "react";
import { Main } from "./Main";
import "../assets/styles/additems.css";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MinimizeIcon from "@mui/icons-material/Minimize";
import AddIcon from "@mui/icons-material/Add";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const AddItems = () => {
  const [showMinimize, setShowMinimize] = useState(true);
  const [showExpand, setShowExpand] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);

  const toggleAddItemWrapper = () => {
    setShowMinimize(!showMinimize);
    setShowExpand(!showExpand);
    const addItemWrapper = document.querySelector(".add-item-wrapper");
    addItemWrapper.classList.toggle("minimize");
  };

  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#FFEDC9",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: "#FFEDC9 !important",
            },
          },
        },
        MuiTableBody: {
          styleOverrides: {
            root: {
              // height: "100%",
              zIndex: "1000",
              position: "relative",
              backgroundColor: "#FFEDC9 !important",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              backgroundColor: "#FFEDC9",
            },
          },
        },
      },
    });

  const toggleActions = (e) => {
    const actionsIcon = e.target;
    const targetActionsIconWrapper = actionsIcon.closest(
      ".actions-icon-wrapper"
    );
    const actionsWrapper =
      targetActionsIconWrapper.querySelector(".actions-wrapper");
    actionsWrapper.classList.toggle("show");
    hideAllActionsWrappers(actionsWrapper);
    console.log(actionsWrapper);
  };

  const hideAllActionsWrappers = (targetActionsWrapper) => {
    let actionsWrapper = Array.from(
      document.querySelectorAll(".actions-wrapper")
    );
    actionsWrapper = actionsWrapper.filter((item) => {
      return item !== targetActionsWrapper;
    });
    actionsWrapper.forEach((item) => {
      item.classList.remove("show");
    });
  };
  const columns = [
    { name: "area name", label: "Area Name" },
    {
      name: "area description",
      label: "Area Description",
    },
    { name: "number of staff", label: "Number Of Staff" },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="actions-icon-wrapper">
              <div className="actions-wrapper">
                <ul>
                  <li>Show</li>
                  <li>Edit</li>
                  <li>Delete</li>
                </ul>
              </div>
              <MoreVertIcon
                onClick={(e) => toggleActions(e)}
                style={{ cursor: "pointer" }}
              />
            </div>
          );
        },
      },
    },
  ];

  const data = [
    ["Joe James", "Test Corp", "Yonkers"],
    ["John Walsh", "Test Corp", "Hartford"],
    ["Bob Herm", "Test Corp", "Tampa"],
    ["James Houston", "Test Corp", "Dallas"],
  ];

  const options = {
    filterType: "checkbox",
    rowsPerPageOptions: [10, 20, 100],
  };

  return (
    <Main>
      <div className="button-wrapper">
        <button
          className="add-btn"
          onClick={() => setShowAddItem(!showAddItem)}
        >
          Add Item
        </button>
      </div>
      <div className={`add-item good ${showAddItem ? "expand" : ""}`}>
        <div className="tabs-wrapper">
          <div className="tab active">Basic-info</div>
          <div className="tab">Ingredient</div>
          <div className="tab">Special Notes</div>
          <div className="tab">Add-On</div>
        </div>
        <div className="add-item-wrapper">
          <div className="header">
            {showMinimize ? (
              <MinimizeIcon onClick={toggleAddItemWrapper} />
            ) : (
              ""
            )}
            {showExpand ? (
              <AddIcon className="expand" onClick={toggleAddItemWrapper} />
            ) : (
              ""
            )}
          </div>
          <div className="form-wrapper">
            <form className="form">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Area Name</label>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      placeholder="Enter area name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Area Name</label>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      placeholder="Enter area name"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name">Area Name</label>

                  <Box
                    sx={{
                      minWidth: 120,
                      height: "40px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <FormControl fullWidth>
                      {/* <InputLabel
                  sx={{
                    color: "#d14418",
                    fontWeight: "600",
                    marginBottom: "2rem !important",
                  }}
                  id="demo-simple-select-label"
                >
                  Room
                </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value=""
                        label="Age"
                        //   onChange={handleChange}
                        // className="noHover"
                        sx={{ border: "1px solid #d14418", height: "40px" }}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Area Name</label>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      placeholder="Enter area name"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  className="form-control"
                  rows="5"
                  name="description"
                  placeholder="Enter description"
                ></textarea>
              </div>
              <button className="save-area">Save</button>
            </form>
          </div>
        </div>
      </div>

      <div className="areas-table-wrapper">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={"Items Table"}
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </Main>
  );
};

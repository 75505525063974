import "../../assets/styles/login.css";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";
import { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "../ui/Spinner";
import AES from "crypto-js/aes";
import axios from "axios";

export const Login = () => {
  const [loginCredentials, setLoginCredentials] = useState({
    username: "",
    password: "",
  });
  const [hrLogin, setHrLogin] = useState(false);

  const encryptWithAES = (text, passphrase) => {
    return AES.encrypt(text, passphrase).toString();
  };

  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const hrloginEmailRef = useRef();
  const hrloginPasswordRef = useRef();
  const navigator = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validateInputs = () => {
    if (emailInputRef.current.value === "") {
      toast.error("Please enter email");
      return false;
    }

    if (passwordInputRef.current.value === "") {
      toast.error("Please enter password");
      return false;
    }

    return true;
  };

  const handleUnitChange = (e) => {
    setLoginCredentials({
      ...loginCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();
      if (validateInputs()) {
        setIsLoading(true);
        const response = await axiosInstance.post("/login", loginCredentials);
        if (response.data.sts.code === 401) {
          toast.error("Invalid credentials");
          setIsLoading(false);
          return;
        }
        const { employee } = response.data.rs;
        const { token } = response.data.rs;
        const { shiftID } = response.data.rs;
        const { jobCateory } = employee.fkJobCategory;
        sessionStorage.setItem("jobCategory", jobCateory);
        sessionStorage.setItem("shiftID", shiftID);
        if (token) {
          setIsLoading(false);
          sessionStorage.setItem("employee", JSON.stringify(employee));
          const encryptedToken = encryptWithAES(token, "userToken");
          sessionStorage.setItem("ut", encryptedToken);
          navigator("/cashier");
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error);
    }
  };

  const handleApiFormSubmit = async (e) => {
    try {
      e.preventDefault();
      setHrLogin(true);
      setIsLoading(true);

      try {
        const credentialsResponse = await axios.post(
          "https://shop.smartqr.app/check-credentials",
          loginCredentials
        );

        if (credentialsResponse.data.success) {
          hrloginEmailRef.current.value = loginCredentials.username;
          hrloginPasswordRef.current.value = loginCredentials.password;

          document.querySelector(".svloginForm").submit();
          setLoginCredentials({ username: "", password: "" });

          setTimeout(() => {
            setIsLoading(false);
            setHrLogin(false);
          }, 2000);
        }
        toast.error(credentialsResponse.data.error);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-box">
        <div className="logo-wrapper">
          <img src={logo} alt="logo" />
        </div>
        <div className="login-form-wrapper">
          <form
            className="form"
            onSubmit={(e) => {
              handleFormSubmit(e);
            }}
          >
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                ref={emailInputRef}
                className="form-control"
                name="username"
                type="text"
                value={loginCredentials.username}
                placeholder="Enter your e-mail"
                onChange={(e) => {
                  handleUnitChange(e);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                className="form-control"
                name="password"
                type="password"
                ref={passwordInputRef}
                value={loginCredentials.password}
                placeholder="password"
                onChange={(e) => {
                  handleUnitChange(e);
                }}
              />
            </div>
            <Link to="/reset-your-password">Forget Password?</Link>

            <button className="login-btn" disabled={isLoading}>
              {isLoading && !hrLogin ? <Spinner /> : <span>Login</span>}
            </button>
            {/* <a href={hrLogin} rel="noreferrer" target="_blank">
              HR Login
            </a> */}
            {/* <Link href={hrLogin} rel="noreferrer" target="_blank">
              HR Login
            </Link> */}
          </form>

          <form
            onSubmit={(e) => {
              handleApiFormSubmit(e);
            }}
          >
            <button className="login-btn" disabled={isLoading}>
              {isLoading && hrLogin ? <Spinner /> : <span>HR Login</span>}
            </button>
          </form>
          <form
            action="https://shop.smartqr.app/hrlogin"
            method="post"
            target="_blank"
            className="svloginForm"
          >
            <input
              type="hidden"
              id="hrloginusername"
              ref={hrloginEmailRef}
              name="username"
              value=""
            />
            <input
              type="hidden"
              id="hrloginpassword"
              ref={hrloginPasswordRef}
              name="password"
              value=""
            />
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

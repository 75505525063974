/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Items } from "./Items";
import "../../assets/styles/cashier.css";
import "../../assets/styles/utils.css";
import logo from "../../assets/images/logo.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import RefreshIcon from "@mui/icons-material/Refresh";
import userImage from "../../assets/images/user.png";
import { Spinner } from "../ui/Spinner";

import { Takeaway } from "./Takeaway";
import { Dinein } from "./Dinein";
import axiosAuthInstance from "../../api/axiosAuthInstance";

import { Categories } from "./Categories";
import { HallTableWaiterModal } from "./HallTableWaiterModal";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PaymentModal } from "./PaymentModal";
import { Calculator } from "./Calculator";
import { ReturnModal } from "./ReturnModal";
import { WaiterFill } from "./WaiterFill";
import waiterfillAxios from "../../api/waiterfillAxios";

export const Cashier = () => {
  const navigator = useNavigate();
  const token = sessionStorage.getItem("ut");

  const [isLoading, setIsLoading] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTakeaway, setShowTakeaway] = useState(false);
  const [showDinein, setShowDinein] = useState(false);
  const [showWaiterFill, setShowWaiterFill] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderMaster, setOrderMaster] = useState(null);
  const [orderDetailToModify, setOrderDetailToModify] = useState(null);

  const [items, setItems] = useState(null);
  const [itemNotes, setItemNotes] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [returnedQuantity, setReturnedQuantity] = useState(1);
  const [addedItems, setAddedItems] = useState([]);
  const [subtotal, setSubTotal] = useState(0);
  const [oldSubTotal, setOldSubTotal] = useState(0);
  const [discountedTotal, setDiscountedTotal] = useState(0);
  const [hall, setHall] = useState(null);
  const [table, setTable] = useState(null);
  const [waiter, setWaiter] = useState(null);
  const [orderType, setOrderType] = useState({ id: 1, type: "dinein" });
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [returnMethod, setReturnMethod] = useState(null);
  const [cashPaidAmount, setCashPaidAmount] = useState(0);
  const [visaPaidAmount, setVisaPaidAmount] = useState(0);
  const [companies, setCompanies] = useState(null);
  const [visaSafes, setvisaSafes] = useState(null);
  const [returnVisaSafe, setReturnVisaSafe] = useState(null);
  const [paymentCompany, setPaymentCompany] = useState(null);
  const [discountsList, setDiscountsList] = useState([
    {
      pkDiscountId: 0,
      discountPercentage: 0,
    },
  ]);

  const [orderMasterId, setOrderMasterId] = useState("");
  const [orderStatus, setOrderStatus] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [discountValue, setDiscountValue] = useState(null);
  const [discountReason, setdiscountReason] = useState("");
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const [updateOrder, setUpdateOrder] = useState(false);
  const [showOrderSearch, setShowOrderSearch] = useState(false);
  const [combineOrderNumber, setCombineOrderNumber] = useState("");
  const [disableCombineButton, setDisableCombineButton] = useState(true);
  const [dineinOrdersCount, setDineinOrdersCount] = useState(null);
  const [waiterfillOrdersCount, setWaiterfillOrdersCount] = useState(null);
  const quantityRef = useRef(null);
  const quantityReturnedRef = useRef(null);

  const linksListRef = useRef();
  const SearchOrderInputRef = useRef();
  const SearchCombineOrderInputRef = useRef();

  const toggleLinksList = () => {
    linksListRef.current.classList.toggle("show");
  };

  const jobCategory = sessionStorage.getItem("jobCategory");
  const employee = JSON.parse(sessionStorage.getItem("employee"));
  const shiftId = JSON.parse(sessionStorage.getItem("shiftID"));

  const closeModal = () => {
    setShowModal(false);
    setDiscountValue(null);
    setDiscountPercentage(0);
    setDiscountedTotal(0);
    setShowPaymentModal(false);
    setShowReturnModal(false);
    setReturnMethod(null);
  };

  const logout = () => {
    sessionStorage.removeItem("employee");
    sessionStorage.removeItem("jobCategory");
    sessionStorage.removeItem("ut");
    sessionStorage.removeItem("shiftID");
    sessionStorage.removeItem("products");
    navigator("/login");
  };

  // use effects
  useEffect(() => {
    if (!sessionStorage.getItem("ut")) {
      navigator("/login");
    } else {
      setWaiter({
        waiterId: employee.pkEmpId,
        waiterName: employee.empName,
      });
    }
  }, []);

  // useEffect(() => {
  //   setWaiter({
  //     waiterId: employee.pkEmpId,
  //     waiterName: employee.empName,
  //   });
  // }, []);

  useEffect(() => {
    if (quantityReturnedRef.current !== null) {
      handleReturnQuantityChange(quantityReturnedRef.current);
    }
  }, [returnedQuantity]);

  const onKeyUp = useCallback(async (e) => {
    if (e.code === "Enter" && searchTerm.trim() !== "") {
      toggleSearchInput();
      searchForOrder(searchTerm);
      window.removeEventListener("keyup", onKeyUp);
      SearchOrderInputRef.current.removeEventListener("blur", onBLur);
    }

    if (e.code === "Enter" && orderMasterId !== "") {
      const orderMasterResponse = await axiosAuthInstance.get(
        `Cashier/GetOrderMaster?userQuery=masterOrderID=${orderMasterId}`
      );

      const { data } = orderMasterResponse;
      if (data.length > 0) {
        if (data[0].Payed === 0) {
          setDisableCombineButton(false);
          return;
        }
        setDisableCombineButton(true);
      }
    }
  });

  const onBLur = useCallback((e) => {
    if (searchTerm.trim() !== "") {
      searchForOrder(searchTerm);
      SearchOrderInputRef.current.removeEventListener("blur", onBLur);
    }
  });

  useEffect(() => {
    let timer;
    let timer2;
    if (searchTerm.trim() !== "") {
      timer = setTimeout(() => {
        SearchOrderInputRef.current.addEventListener("blur", onBLur);
      }, 500);

      timer2 = setTimeout(() => {
        window.addEventListener("keyup", onKeyUp);
      }, 500);
    }

    return () => {
      window.removeEventListener("keyup", onKeyUp);
      if (SearchOrderInputRef.current) {
        SearchOrderInputRef.current.removeEventListener("blur", onBLur);
      }
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, [searchTerm]);

  useEffect(() => {
    let timer;
    if (orderMasterId.trim() !== "") {
      timer = setTimeout(() => {
        window.addEventListener("keyup", onKeyUp);
      }, 500);
    }

    return () => {
      window.removeEventListener("keyup", onKeyUp);
      clearTimeout(timer);
    };
  }, [orderMasterId]);

  useEffect(() => {
    setPaymentMethod("cash");
    // setCashPaidAmount(subtotal);
    setPaymentErrorMessage("");
    setDiscountValue(null);
    setDiscountPercentage(0);
  }, [showPaymentModal]);

  useEffect(() => {
    if (selectedProduct) {
      let modifiedProduct = { ...selectedProduct };
      modifiedProduct.total = parseInt(quantity) * selectedProduct.price;
      setSelectedProduct(modifiedProduct);
    }
  }, [quantity]);

  useEffect(() => {
    calculateSubTotal(addedItems);
  }, [addedItems]);

  useEffect(() => {
    if (selectedProduct) {
      quantityRef.current.select();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (paymentMethod === "mix") {
      let remaining = discountedTotal - cashPaidAmount;
      if (cashPaidAmount > discountedTotal) {
        setCashPaidAmount(discountedTotal);
        return;
      }
      setVisaPaidAmount(remaining);
    }
  }, [cashPaidAmount]);

  useEffect(() => {
    if (paymentMethod === "mix") {
      let remaining = discountedTotal - visaPaidAmount;

      if (visaPaidAmount > discountedTotal) {
        setVisaPaidAmount(discountedTotal);
        return;
      }

      setCashPaidAmount(remaining);
    }
  }, [visaPaidAmount]);

  useEffect(() => {
    if (paymentMethod === "company") {
      fetchCompanies();
    }

    setDiscountedTotal(discountedTotal);
    if (
      paymentMethod === "cash" ||
      paymentMethod === "visa" ||
      paymentMethod === "mix"
    ) {
      setPaymentCompany(null);
      setCompanies(null);
    }

    if (paymentMethod === "cash") {
      setCashPaidAmount(discountedTotal);
      setVisaPaidAmount(0);
    }

    if (paymentMethod === "visa") {
      setVisaPaidAmount(discountedTotal);
      setCashPaidAmount(0);
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (showReturnModal) {
      setReturnMethod("cash");
    }
  }, [showReturnModal]);

  useEffect(() => {
    if (!returnMethod) return;

    if (returnMethod === "visa") {
      if (orderDetailToModify) {
        orderDetailToModify.returnMethod = "visa";
        orderDetailToModify.cash_safe_id = null;
      }
      fetchvisaSafes();
    }

    if (returnMethod === "cash") {
      setReturnVisaSafe(null);
      setvisaSafes(null);
      if (orderDetailToModify) {
        orderDetailToModify.returnMethod = "cash";
        orderDetailToModify.cash_safe_id = employee.fkSafeId;
      }
    }

    if (returnMethod === "company") {
      orderDetailToModify.returnMethod = "company";
      if (orderDetailToModify) {
        orderDetailToModify.cash_safe_id = null;
        orderDetailToModify.visaSafe_id = null;
      }

      setvisaSafes(null);
    }

    // if (orderDetailToModify) {
    //   orderDetailToModify.returnMethod = returnMethod;
    // }
  }, [returnMethod]);

  useEffect(() => {
    if (orderDetailToModify) {
      orderDetailToModify.visaSafe_id = returnVisaSafe;
    }
  }, [returnVisaSafe]);

  useEffect(() => {
    setDiscountValue((discountPercentage / 100) * subtotal);
    setDiscountedTotal((discountPercentage / 100) * subtotal);
    modifyOrderDetailsItemsDiscount(discountPercentage);
    setCashPaidAmount(0);
  }, [discountPercentage]);

  useEffect(() => {
    setDiscountedTotal(subtotal - discountValue);
  }, [discountValue]);

  useEffect(() => {
    fetchDineinOrdersCount();
  }, []);

  useEffect(() => {
    fetchWaiterFillOrdersCount();
  }, []);

  // end use effects

  // toggler functions
  const toggleTakeaway = () => {
    setShowTakeaway(!showTakeaway);
  };

  const toggleDinein = () => {
    setShowDinein(!showDinein);
  };

  const toggleWaiterFill = () => {
    setShowWaiterFill(!showWaiterFill);
  };

  const toggleItems = () => {
    setShowItems(!showItems);
  };

  const toggleSearchInput = () => {
    // setSearchTerm("");

    setShowOrderSearch(!showOrderSearch);
    SearchOrderInputRef.current.classList.toggle("active");
    setTimeout(() => {
      if (SearchOrderInputRef.current.classList.contains("active")) {
        SearchOrderInputRef.current.focus();
        SearchOrderInputRef.current.select();
      }
    }, 100);
  };

  // end toggler functions

  // handler functions
  const handleQuantityOrSearchTermChange = (number) => {
    if (SearchCombineOrderInputRef.current) {
      setOrderMasterId((prevValue) => prevValue + number);
      return;
    }

    if (!selectedProduct && showOrderSearch) {
      setSearchTerm((prevValue) => prevValue + number);
      return;
    }

    if (selectedProduct) {
      number = parseInt(number);
      if (number === 0 && !quantity) {
        return;
      }

      if (number === 0 && quantity !== null) {
        setQuantity(number);
        return;
      }

      setQuantity(number);
    }

    if (orderStatus === "toModify") {
      setReturnedQuantity(number);
      return;
    }
  };

  const handleDeleteNumber = () => {
    setQuantity(0);
  };

  const handleSelectNote = (note) => {
    if (!selectedNotes.includes(note)) {
      setSelectedNotes([...selectedNotes, note]);
    }
  };

  const handleSelectProduct = (product) => {
    let existedItem = null;

    product.total = parseFloat(product.price) * parseInt(quantity);
    setSelectedNotes([]);
    if (!existedItem || existedItem.length === 0) {
      setSelectedProduct(product);
    }
    setQuantity(1);
  };

  const handleRemoveSelectedItem = () => {
    setSelectedProduct(null);
    setItemNotes(null);
    setQuantity(1);
  };

  const handleAddSelectedItem = () => {
    if (!selectedProduct) {
      Swal.fire({
        title: "Error!",
        text: "Please Add Item!",
        icon: "error",
        confirmButtonText: "Ok",
      });

      return;
    }
    if (!quantity || parseInt(quantity) === 0) {
      Swal.fire({
        title: "Error!",
        text: "Quantity must be at  least 1!",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    let modifiedselectedProduct = { ...selectedProduct };
    modifiedselectedProduct["ItemName"] = selectedProduct.itemName;
    modifiedselectedProduct["quantity"] = quantity;
    modifiedselectedProduct["Notes"] = selectedNotes;
    modifiedselectedProduct["total"] = parseFloat(
      modifiedselectedProduct.price * quantity
    );
    setAddedItems([...addedItems, modifiedselectedProduct]);

    if (subtotal === 0) {
      setOrderStatus("toPrepare");
    }

    if (oldSubTotal !== 0) {
      setOrderStatus("toUpdate");
    }
    // setAddedItems([...addedItems, modifiedselectedProduct]);

    handleRemoveSelectedItem();
  };

  const handleRemoveAddedItem = (item) => {
    const filteredItems = addedItems.filter((addedItem) => {
      return addedItem.pkItemId !== item.pkItemId;
    });
    setAddedItems(filteredItems);
    setSubTotal(subtotal - parseFloat(item.total));
  };

  const handleDiscountChange = (e) => {
    setDiscountPercentage(e.target.value);
  };

  const handlePaymentDone = () => {
    if (
      paymentMethod === "mix" &&
      discountedTotal > 0 &&
      cashPaidAmount === 0 &&
      visaPaidAmount === 0
    ) {
      setPaymentErrorMessage(
        "Paid cash amount and visa must be euqal to order total"
      );
      return false;
    }
    setShowPaymentModal(false);
    saveOrder();
  };

  const handleCheckReturnOrder = () => {
    if (orderMaster.Payed === 0) {
      Swal.fire({
        title: "Error!",
        text: "Return available for payed orders only, you can use void instead for unpaid orders!",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    setShowReturnModal(!showReturnModal);
  };

  const handleReturnOrder = async () => {
    setShowReturnModal(!showReturnModal);

    if (!orderMaster) {
      Swal.fire({
        title: "Not found!",
        text: "Please select order!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    try {
      const returnOrderObj = {
        orderMasterID: orderMaster.PK_OrderMaster_ID,
        returnMethod,
        cash_safe_id: returnVisaSafe ? null : employee.fkSafeId,
        visaSafe_id: returnVisaSafe ? returnVisaSafe : null,
      };

      await axiosAuthInstance.post(`Cashier/ReturnOrder`, returnOrderObj);

      Swal.fire({
        title: "Success!",
        text: "Order marked as returned!",
        icon: "success",
        confirmButtonText: "Ok",
      });

      clearStates();
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleReturnOrderDetails = async (e, orderDetail) => {
    if (!orderDetail) {
      Swal.fire({
        title: "Not found!",
        text: "Please select order detail!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    try {
      setOrderDetailToModify(orderDetail);
      setReturnedQuantity(1);
      const targetedRow = e.target.closest("tr");
      document
        .querySelectorAll(".returnedQuantity")
        .forEach((item) => item.classList.add("d-none"));

      targetedRow.classList.add("disabled");
      if (orderDetail.Qty > 1 && targetedRow.classList.contains("disabled")) {
        const returnedQuantityInput =
          targetedRow.querySelector(".returnedQuantity");
        quantityReturnedRef.current = returnedQuantityInput;
        quantityReturnedRef.current.classList.toggle("d-none");
        quantityReturnedRef.current.focus();
        quantityReturnedRef.current.select();
        quantityReturnedRef.current.value = quantity;
      }

      addedItems.filter((item) => {
        if (item.PK_OrderDetails_ID === orderDetail.PK_OrderDetails_ID) {
          // item.returnedQuantity = 1;
          item.quantityToReturn = 1;
          item.return = !item.return;
          // item.returnMethod = returnMethod;
          // item.cash_safe_id = returnVisaSafe ? null : employee.fkSafeId;
          // item.visaSafe_id = returnVisaSafe ? returnVisaSafe : null;
          return item;
        }
        return true;
      });

      if (orderStatus !== "toModify") {
        setOrderStatus("toModify");
      }
      return;
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleReturnQuantityChange = (e, orderDetail) => {
    const quantityInput = quantityReturnedRef.current;
    let availabledQuantityToReturn =
      orderDetailToModify.Qty - orderDetailToModify.ReturnedQuantity;

    if (
      orderDetailToModify.ReturnedQuantity > 0 &&
      quantityInput.value > availabledQuantityToReturn
    ) {
      setReturnedQuantity(availabledQuantityToReturn);
      quantityInput.value = availabledQuantityToReturn;
    }

    if (
      orderDetailToModify.ReturnedQuantity === 0 &&
      quantityInput.value > orderDetailToModify.Qty
    ) {
      setReturnedQuantity(availabledQuantityToReturn);
      quantityInput.value = availabledQuantityToReturn;
    }

    addedItems.filter((item) => {
      if (item.PK_OrderDetails_ID === orderDetailToModify.PK_OrderDetails_ID) {
        item.quantityToReturn = parseInt(quantityInput.value);

        // item.quantityToReturn = orderDetailToModify.ReturnedQuantity
        //   ? parseInt(orderDetailToModify.ReturnedQuantity) +
        //     parseInt(quantityInput.value)
        //   : quantityInput.value;

        // item.ReturnedQuantity =
        //   quantityInput.value !== "" ? quantityInput.value : orderDetail.Qty;
        return item;
      }
      return true;
    });
  };

  const handleVoidOrderDetail = async (e, orderDetail) => {
    if (!orderDetail) {
      Swal.fire({
        title: "Not found!",
        text: "Please select order detail!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }

    try {
      const targetedRow = e.target.closest("tr");
      targetedRow.classList.toggle("disabled");
      addedItems.filter((item) => {
        if (item.PK_OrderDetails_ID === orderDetail.PK_OrderDetails_ID) {
          item.Void = !item.Void;
          return item;
        }
        return true;
      });

      if (orderStatus !== "toModify") {
        setOrderStatus("toModify");
      }
      return;
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  // end handler functions

  // render functions
  const renderHallTableWaiterModal = () => {
    return (
      <HallTableWaiterModal
        closeModal={closeModal}
        setHall={setHall}
        setTable={setTable}
        setOrderType={setOrderType}
      />
    );
  };

  const renderMixPaymentInputs = () => {
    return (
      <Fragment>
        <div className="paymentvalueswrapper">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="">Cash Value</label>
              <input
                type="number"
                min="0"
                max="100"
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  border: "1px solid grey",
                }}
                id="cashPaidAmount"
                value={cashPaidAmount}
                onChange={(e) => setCashPaidAmount(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="">Visa Value</label>
              <input
                type="number"
                max={subtotal}
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  border: "1px solid grey",
                }}
                value={visaPaidAmount}
                onChange={(e) => setVisaPaidAmount(e.target.value)}
                className="form-control"
                id="visaPaidAmount"
              />
            </div>
          </div>
          <span className="error-span">
            {paymentErrorMessage ? paymentErrorMessage : ""}
          </span>
        </div>
      </Fragment>
    );
  };

  const renderPaymentModal = () => {
    const customStyle = { width: "40%" };
    let currentSelectedOption = 0;
    return (
      <PaymentModal
        closeModal={closeModal}
        customStyle={customStyle}
        subtotal={subtotal}
        oldSubTotal={oldSubTotal}
        handleDiscountChange={handleDiscountChange}
        currentSelectedOption={currentSelectedOption}
        discountsList={discountsList}
        discountReason={discountReason}
        setdiscountReason={setdiscountReason}
        discountPercentage={discountPercentage}
        discountValue={discountValue}
        discountedTotal={discountedTotal}
        setDiscountedTotal={setDiscountedTotal}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        renderMixPaymentInputs={renderMixPaymentInputs}
        showPaymentModal={showPaymentModal}
        handlePaymentDone={handlePaymentDone}
        setShowPaymentModal={setShowPaymentModal}
        setCashPaidAmount={setCashPaidAmount}
        setVisaPaidAmount={setVisaPaidAmount}
        companies={companies}
        setPaymentCompany={setPaymentCompany}
      />
    );
  };

  const renderReturnModal = () => {
    const customStyle = { width: "40%" };
    let currentSelectedOption = 0;
    return (
      <ReturnModal
        closeModal={closeModal}
        customStyle={customStyle}
        subtotal={subtotal}
        oldSubTotal={oldSubTotal}
        handleDiscountChange={handleDiscountChange}
        currentSelectedOption={currentSelectedOption}
        discountsList={discountsList}
        discountReason={discountReason}
        setdiscountReason={setdiscountReason}
        discountPercentage={discountPercentage}
        discountValue={discountValue}
        discountedTotal={discountedTotal}
        setDiscountedTotal={setDiscountedTotal}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        renderMixPaymentInputs={renderMixPaymentInputs}
        showReturnModal={showReturnModal}
        handlePaymentDone={handlePaymentDone}
        setShowReturnModal={setShowReturnModal}
        setCashPaidAmount={setCashPaidAmount}
        setVisaPaidAmount={setVisaPaidAmount}
        visaSafes={visaSafes}
        setPaymentCompany={setPaymentCompany}
        orderMaster={orderMaster}
        setReturnMethod={setReturnMethod}
        returnMethod={returnMethod}
        setReturnVisaSafe={setReturnVisaSafe}
        handleReturnOrder={handleReturnOrder}
        orderDetailToModify={orderDetailToModify}
      />
    );
  };

  const renderWaiterActions = (item) => {
    if (!orderMaster) {
      return (
        <DeleteIcon
          onClick={() => handleRemoveAddedItem(item)}
          style={{ cursor: "pointer" }}
        />
      );
    }
  };

  const renderNonWaiterActions = (item) => {
    if (orderMaster) {
      if (orderMaster.Payed > 0 && item.PK_OrderDetails_ID) {
        return (
          <Fragment>
            {(!item.Void && item.ReturnedQuantity === 0) ||
            item.ReturnedQuantity < item.Qty ? (
              <RefreshIcon
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  handleReturnOrderDetails(e, item);
                  setShowReturnModal(!showReturnModal);
                }}
              />
            ) : (
              ""
            )}
          </Fragment>
        );
      }

      if (orderMaster.Payed === 0 && orderMaster.FK_OrderTypeID === 1) {
        return !item.ItemClose ? (
          <BlockIcon
            style={{ cursor: "pointer" }}
            onClick={(e) => handleVoidOrderDetail(e, item)}
          />
        ) : (
          ""
        );
      }
    } else {
      return (
        <DeleteIcon
          onClick={() => handleRemoveAddedItem(item)}
          style={{ cursor: "pointer" }}
        />
      );
    }
  };

  // end render functions

  // calculating functions
  const calculateSubTotal = (addedItems, oldTotal = null) => {
    let subTotal = 0;
    if (addedItems.length > 0) {
      addedItems.forEach((item) => {
        subTotal +=
          item.Void || item.ReturnedQuantity === item.quantity
            ? 0
            : item.price * item.quantity;
      });
      if (oldTotal) {
        setOldSubTotal(subTotal);
      }

      setSubTotal(subTotal + oldSubTotal);
      setDiscountedTotal(subTotal + oldSubTotal);
    }
  };
  // end calculating functions

  // fetcher functions

  const fetchDineinOrdersCount = async () => {
    try {
      const { data } = await axiosAuthInstance.get(
        "Cashier/CurrentDinInOrdersCount"
      );

      setDineinOrdersCount(data);
    } catch (error) {}
  };

  const fetchWaiterFillOrdersCount = async () => {
    try {
      const { data } = await waiterfillAxios.get(
        "main_orders_count?shopId=WaqL2LPTI"
      );

      setWaiterfillOrdersCount(data);
    } catch (error) {}
  };

  const fetchItems = async (categoryId) => {
    let cachedProducts = JSON.parse(sessionStorage.getItem("products"));
    try {
      if (cachedProducts && cachedProducts[categoryId]) {
        setItems(cachedProducts[categoryId]);
        setShowItems(true);
        return;
      }
      const response = await axiosAuthInstance.get(
        `/TblItem/getPage?pageSize=100&userQuery=fkCategoryId=${categoryId}`
      );

      const { rs } = response.data;

      if (cachedProducts) {
        let newCachedProducts = JSON.parse(sessionStorage.getItem("products"));
        newCachedProducts[categoryId] = rs.data;
        sessionStorage.setItem("products", JSON.stringify(newCachedProducts));
        setItems(rs.data);
        setShowItems(!showItems);
        return;
      }

      let products = {};
      products[categoryId] = rs.data;
      sessionStorage.setItem("products", JSON.stringify(products));

      setItems(rs.data);
      setShowItems(!showItems);
      return;
    } catch (error) {}
  };

  const showOrderDetails = async (
    pkOrderMasterId,
    hallId,
    hallName,
    tableId,
    tableName,
    waiterId,
    waiterName
  ) => {
    try {
      let hall = {
        hallId: hallId,
        hallName: hallName,
      };

      let table = {
        tableId: tableId,
        tableName: tableName,
      };

      let waiter = {
        waiterId: waiterId,
        waiterName: waiterName,
      };

      setHall(hall);
      setTable(table);
      setWaiter(waiter);

      setOldSubTotal(0);
      setAddedItems([]);
      // setOrderMasterId(pkOrderMasterId);
      setUpdateOrder(!updateOrder);

      const orderMasterResponse = await axiosAuthInstance.get(
        `Cashier/GetOrderMaster?userQuery=masterOrderID=${pkOrderMasterId}`
      );

      setOrderMaster(orderMasterResponse.data[0]);

      let orderDetailsData = await fetchDineinOrderDetails(pkOrderMasterId);
      orderDetailsData = orderDetailsData.map((item) => {
        item.quantity = item.Qty;
        item.price = item.Price;
        item.total = item.Qty * item.Price;
        return item;
      });
      calculateSubTotal(orderDetailsData, true);
      setShowDinein(!showDinein);
      setOrderStatus("toUpdate");
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something Went Wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const fetchDineinOrderDetails = async (pkOrderMasterId) => {
    const dineIneOrderDetailsResponse = await axiosAuthInstance.get(
      `/Cashier/getOrderDetails?userQuery=fkOrderMasterId=${pkOrderMasterId}`
    );

    return dineIneOrderDetailsResponse.data;
  };

  const searchForOrder = async (searchTerm) => {
    if (!searchTerm) return;

    try {
      const orderMasterResponse = await axiosAuthInstance.get(
        `Cashier/GetOrderMaster?userQuery=masterOrderID=${searchTerm}`
      );

      if (orderMasterResponse.data.length === 0) {
        Swal.fire({
          title: "Not found!",
          text: "Order not found!",
          icon: "error",
          confirmButtonText: "Ok",
        });

        setAddedItems([]);
        setSearchTerm("");
        clearTotals();
        // toggleSearchInput();

        return;
      }

      setOrderMaster(orderMasterResponse.data[0]);

      let hall = {
        hallId: orderMasterResponse.data[0].FK_HallID,
        hallName: orderMasterResponse.data[0].HallName,
      };

      let table = {
        tableId: orderMasterResponse.data[0].FK_Table_ID,
        tableName: orderMasterResponse.data[0].TableName,
      };

      let waiter = {
        waiterId: orderMasterResponse.data[0].FK_WaiterID,
        waiterName: orderMasterResponse.data[0].Waiter,
      };

      setHall(hall);
      setTable(table);
      setWaiter(waiter);

      const { data } = await axiosAuthInstance.get(
        `/Cashier/getOrderDetails?userQuery=fkOrderMasterId=${searchTerm}`
      );

      const orderDetailsData = data.map((item) => {
        item.quantity = item.Qty;
        item.price = item.Price;
        item.total = item.Qty * item.Price;

        return item;
      });

      setAddedItems(orderDetailsData);
      setOrderStatus("toModify");

      if (SearchOrderInputRef.current.classList.contains("active")) {
        toggleSearchInput();
      }
      setSearchTerm("");
      setSubTotal(0);
      setOldSubTotal(0);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const fetchCompanies = async () => {
    const companiesResponse = await axiosAuthInstance.get(
      `/TblCompany/getPage?pageSize=100`
    );
    setCompanies(companiesResponse.data.rs.data);
    setPaymentCompany(companiesResponse.data.rs.data[0].pkCompanyId);
  };

  const fetchvisaSafes = async () => {
    let unFilteredSafes;
    let filteredSafes;

    const visaSafesResponse = await axiosAuthInstance.get(
      // `/tblSafe/getPage?safe_type=visa&pageSize=100`
      `/TblSafe/getPage?safe_type=visa`
    );

    unFilteredSafes = visaSafesResponse.data.rs.data;
    filteredSafes = unFilteredSafes.filter((item) => {
      return item["safe_type"] === "Visa";
    });
    setvisaSafes(filteredSafes);
    setReturnVisaSafe(visaSafesResponse.data.rs.data[0].pkSafeId);
  };
  // end fetcher functions

  // saver functions

  const handleReturnOrderItems = (order, orderDetails) => {
    orderDetails.forEach(async (item) => {
      await axiosAuthInstance.post("/Cashier/ReturnOrderItem", {
        orderDetailID: item.pkOrderDetailsId,
        itemQty: item.returnedQuantity,
        returnMethod: item.returnMethod,
        cash_safe_id: item.cash_safe_id,
        visaSafe_id: item.visaSafe_id,
      });
    });

    setIsLoading(false);

    Swal.fire({
      title: "Success!",
      text: "Order items returned successfully",
      icon: "success",
      confirmButtonText: "Ok",
    });

    clearStates();
  };

  const saveOrder = async () => {
    try {
      if (!checkForAddedItems()) return false;

      if (jobCategory === "جرسون" && orderType.id === 1) {
        if (!checkForSelectedHallAndTable()) return false;
      }

      const order = {};

      if (orderMaster) {
        order.pkOrderMasterId = orderMaster.PK_OrderMaster_ID;
      }
      order.totalPrice = subtotal;

      // if (jobCategory === "جرسون") {
      //   order.fkOrderTypeId = 1;
      // }

      // if (jobCategory !== "جرسون") {
      //   if (orderMaster) {
      //     order.fkOrderTypeId = orderMaster.FK_OrderTypeID;
      //   } else {
      //     order.fkOrderTypeId = 2;
      //   }
      // }

      order.fkDeliveryBoyId = null;
      if (orderMaster && orderStatus === "pay&print") {
        order.fkShiftD = parseInt(sessionStorage.getItem("shiftID"));
        order.fkSafeId = employee.fkSafeId;
      }

      if (orderMaster && orderStatus !== "pay&print") {
        order.fkShiftD = orderMaster.FK_ShiftD;
        order.fkSafeId = orderMaster.FK_SafeID;
      }

      if (orderMaster) {
        order.fkWaiterId = orderMaster.FK_WaiterID;
        order.fkOrderTypeId = orderMaster.FK_OrderTypeID;
        order.discount = orderMaster.Discount
          ? orderMaster.Discount
          : discountPercentage;
        order.close = orderStatus === "pay&print" ? true : orderMaster.Close;
        order.done = orderStatus === "pay&print" ? true : orderMaster.Done;
        if (paymentCompany) {
          order.cashValue = 0;
          order.visaValue = 0;
        } else {
          order.cashValue =
            orderStatus === "pay&print" && paymentMethod === "cash"
              ? discountedTotal
              : orderStatus === "pay&print" && paymentMethod === "visa"
              ? 0
              : orderStatus === "pay&print" && paymentMethod === "mix"
              ? cashPaidAmount
              : orderMaster.CashValue;
          order.visaValue =
            orderStatus === "pay&print" && paymentMethod === "visa"
              ? discountedTotal
              : orderStatus === "pay&print" && paymentMethod === "cash"
              ? 0
              : orderStatus === "pay&print" && paymentMethod === "mix"
              ? visaPaidAmount
              : orderMaster.VisaValue;
        }

        order.isCompany =
          orderStatus === "pay&print" && paymentMethod === "company"
            ? true
            : orderMaster.IsCompany;

        order.isCash =
          orderStatus === "pay&print" && paymentMethod === "cash"
            ? true
            : orderMaster.IsCash;
        order.isVisa =
          orderStatus === "pay&print" && paymentMethod === "visa"
            ? true
            : orderMaster.Isvisa;
        order.isMix =
          orderStatus === "pay&print" && paymentMethod === "mix"
            ? true
            : orderMaster.IsMix;
        order.payed =
          orderStatus === "pay&print" || order.isCompany
            ? discountedTotal
            : orderMaster.Payed >= 0
            ? orderMaster.Payed
            : 0;
      } else {
        order.fkOrderTypeId = jobCategory === "جرسون" ? 1 : 2;
        order.fkWaiterId = order.fkOrderTypeId === 2 ? null : employee.pkEmpId;
        order.fkShiftD = parseInt(sessionStorage.getItem("shiftID"));
        order.fkSafeId = employee.fkSafeId;
        order.discount = discountPercentage ? discountPercentage : 0;
        order.close = orderStatus === "pay&print" ? true : false;
        order.done = orderStatus === "pay&print" ? true : false;
        order.cashValue =
          orderStatus === "pay&print" && paymentMethod === "cash"
            ? discountedTotal
            : orderStatus === "pay&print" && paymentMethod === "visa"
            ? 0
            : cashPaidAmount;

        order.visaValue =
          orderStatus === "pay&print" && paymentMethod === "visa"
            ? discountedTotal
            : orderStatus === "pay&print" && paymentMethod === "cash"
            ? 0
            : visaPaidAmount;
        order.isCash =
          orderStatus === "pay&print" && paymentMethod === "cash"
            ? true
            : false;
        order.isvisa =
          orderStatus === "pay&print" && paymentMethod === "visa"
            ? true
            : false;

        order.isMix =
          orderStatus === "pay&print" && paymentMethod === "mix" ? true : false;
        order.isCompany = paymentCompany ? true : false;

        order.payed = orderStatus === "pay&print" ? discountedTotal : 0;
      }

      order.fkClientId = null;
      order.fk_Company_ID = paymentCompany;
      order.compOrderValue = paymentCompany ? discountedTotal : 0;
      order.date = new Date();
      order.fkBranchId = employee.fkBranchId;
      order.orderAdress = null;
      order.orderTel = null;
      order.onHold = null;
      order.carNo = null;
      order.carType = null;
      order.fkColorId = null;
      order.carColor = null;
      order.delivered = null;
      order.readyToPay = null;
      order.ishost = null;
      order.hosting = null;
      order.isCombined = null;
      order.fkOrderMasterIdcombinedFrom = null;
      order.discountReason = discountReason;

      // return;
      // create order

      // Creating objects for order details api

      let orderDetails = [];
      addedItems.forEach((item) => {
        let orderDetailsItem = {};
        if (item.PK_OrderDetails_ID) {
          orderDetailsItem.pkOrderDetailsId = item.PK_OrderDetails_ID;
        }

        if (orderMaster) {
          orderDetailsItem.fkOrderMasterId = orderMaster.PK_OrderMaster_ID;
        }

        if (item.ReturnedQuantity) {
          orderDetailsItem.returnedQuantity = item.ReturnedQuantity;
        }

        if (item.quantityToReturn) {
          let quantityToBeReturned = parseInt(item.quantityToReturn) ?? 0;
          orderDetailsItem.returnedQuantity = quantityToBeReturned;
        }

        orderDetailsItem.returnMethod = item.returnMethod
          ? item.returnMethod
          : null;
        orderDetailsItem.cash_safe_id = item.cash_safe_id ?? null;
        orderDetailsItem.visaSafe_id = item.visaSafe_id ?? null;
        orderDetailsItem.void = item.Void ?? false;

        orderDetailsItem.fkItemId = item.pkItemId;
        orderDetailsItem.qty = item.quantity;
        orderDetailsItem.discount = item.Discount ? item.Discount : 0;
        orderDetailsItem.notes = item.Notes?.toString() || null;
        orderDetailsItem.done = orderDetailsItem.void
          ? true
          : orderStatus === "pay&print"
          ? true
          : item.Done
          ? item.Done
          : false;
        orderDetailsItem.return = item.return ? item.return : false;
        orderDetailsItem.price = item.price;
        orderDetailsItem.itemClose = orderDetailsItem.void
          ? true
          : item.ItemClose
          ? item.ItemClose
          : orderStatus === "pay&print"
          ? true
          : false;
        orderDetailsItem.cost = 0;
        orderDetailsItem.detailsIndex = 0;
        orderDetailsItem.saledPrice = 0;
        if (orderStatus === "pay&print") {
          if (!orderDetailsItem.Void) {
            orderDetails.push(orderDetailsItem);
          }
        } else {
          orderDetails.push(orderDetailsItem);
        }
      });

      setIsLoading(!isLoading);

      if (orderDetailToModify) {
        const returnedOrderDetails = orderDetails.filter((item) => {
          return item.hasOwnProperty("returnedQuantity");
        });
        handleReturnOrderItems(order, returnedOrderDetails);
        return;
      }

      const saveUpdateOrderResponse = await axiosAuthInstance.post(
        `cashier/saveUpdateOrder`,
        {
          orderMaster: order,
          orderDetails: orderDetails,
        }
      );

      if (saveUpdateOrderResponse.data.rs.errMsg) {
        Swal.fire({
          title: "Error!",
          text: `${saveUpdateOrderResponse.data.rs.errMsg}. Please Login again to open shift`,
          icon: "error",
          confirmButtonText: "Ok",
        });
        // clearStates();
        setIsLoading(false);
        return;
      }
      const { pkOrderMasterID } = saveUpdateOrderResponse.data.rs;

      if (orderStatus === "toPrepare" || orderStatus === "toUpdate") {
        printer(`https://dinerprint.smartvillageqatar.com/cashier/invoice.aspx?reportKey=${pkOrderMasterID}&shiftid=${shiftId}&userid=${employee.pkEmpId}&branchid=${employee.fkBranchId}&LangeSymbol=EN&PrintDinin=Print&PrintOnly=False
        `);
      } else if (orderStatus === "pay&print") {
        console.log(
          pkOrderMasterID,
          shiftId,
          employee.pkEmpId,
          employee.fkBranchId
        );
        printer(
          `https://dinerprint.smartvillageqatar.com/cashier/invoice.aspx?reportKey=${pkOrderMasterID}&shiftid=${shiftId}&userid=${employee.pkEmpId}&branchid=${employee.fkBranchId}&LangeSymbol=EN&ordertype=Order&PrintDinin=Don'tPrint&PrintOnly=False`
        );
      }

      if (orderStatus === "pay&print") {
        await axiosAuthInstance.post(`cashier/payOrder`, {
          orderMasterID: pkOrderMasterID,
        });
      }

      if (
        dineinOrdersCount >= 1 &&
        order.fkOrderTypeId === 1 &&
        orderStatus === "pay&print"
      ) {
        setDineinOrdersCount(dineinOrdersCount - 1);
      }

      if (table && order.fkOrderTypeId === 1) {
        let orderTableObj = {
          fK_Table_ID: table.tableId,
          masterOrderID: pkOrderMasterID,
          exist: true,
        };

        await axiosAuthInstance.post(
          `Cashier/SaveUpdateOrderTable`,
          orderTableObj
        );
      }

      if (orderStatus === "toPrepare" && orderType.id === 1) {
        setDineinOrdersCount(dineinOrdersCount + 1);
      }
      clearStates();

      Swal.fire({
        title: "Success!",
        text:
          orderStatus === "toModify"
            ? "Any updates will be added to order!"
            : "Order Added!",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      setIsLoading(false);

      Swal.fire({
        title: "Error!",
        text: "Something Went Wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const printer = (fileUrl) => {
    const newWin = window.open(fileUrl, "printing", "width=11,height=11");

    setTimeout(() => {
      newWin.close();
    }, 5000);
  };

  const onlyPrintForDineinOrder = async (pkOrderMasterId) => {
    try {
      // printer(`http://arabicresturanttest.smartvillageqatar.com/cashier/invoice.aspx?reportKey=${pkOrderMasterId}&shiftid=1&userid=${employee.pkEmpId}&branchid=${employee.fkBranchId}&LangeSymbol=EN
      // `);

      printer(
        `https://dinerprint.smartvillageqatar.com/cashier/invoice.aspx?reportKey=${pkOrderMasterId}&shiftid=${shiftId}&userid=${employee.pkEmpId}&branchid=${employee.fkBranchId}&LangeSymbol=EN&ordertype=Order&PrintDinin=Don'tPrint&PrintOnly=True`
      );

      clearStates();
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something Went Wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const payAndPrintForDineinOrder = async (pkOrderMasterId) => {
    try {
      // setOrderMasterId(pkOrderMasterId);
      const orderMasterResponse = await axiosAuthInstance.get(
        `Cashier/GetOrderMaster?userQuery=masterOrderID=${pkOrderMasterId}`
      );

      setOrderMaster(orderMasterResponse.data[0]);
      let orderDetailsData = await fetchDineinOrderDetails(pkOrderMasterId);
      orderDetailsData = orderDetailsData.map((item) => {
        item.quantity = item.Qty;
        item.price = item.Price;
        item.total = item.Qty * item.Price;
        return item;
      });

      if (discountsList.length === 1) {
        const discountsResponse = await axiosAuthInstance.get(
          "TblDiscount/getPage?pageSize=100"
        );
        const { data } = discountsResponse.data.rs;
        data.unshift(discountsList[0]);
        setDiscountsList(data);
      }

      setOrderStatus("pay&print");
      setAddedItems(orderDetailsData);
      setShowDinein(!showDinein);
      setShowPaymentModal(true);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something Went Wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const combineOrders = async () => {
    try {
      await axiosAuthInstance.post(`cashier/CombineOrders`, {
        masterOrderId: orderMasterId,
        combinedOrderID: combineOrderNumber,
      });

      Swal.fire({
        title: "Success!",
        text: "Orders Combined!",
        icon: "success",
        confirmButtonText: "Ok",
      });

      // clearStates();
      clearCombineStates();
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  // end saver functions

  const payAndPrint = async () => {
    if (!checkForAddedItems()) {
      return false;
    }

    if (discountsList.length === 1) {
      const discountsResponse = await axiosAuthInstance.get(
        "TblDiscount/getPage?pageSize=100"
      );
      const { data } = discountsResponse.data.rs;
      data.unshift(discountsList[0]);
      setDiscountsList(data);
    }

    setOrderStatus("pay&print");
    setShowPaymentModal(!showPaymentModal);
    setDiscountedTotal(subtotal);
  };

  const modifyOrderDetailsItemsDiscount = (discountPercentage) => {
    // let discountedAddedItems = [...addedItems];
    // discountedAddedItems = addedItems.map((item) => {
    //   item.Discount = item.quantity * ((discountPercentage / 100) * item.price);
    //   return item;
    // });
    // setAddedItems(discountedAddedItems);
  };

  // clear functions
  const clearStates = () => {
    setIsLoading(false);
    setAddedItems([]);
    setSelectedProduct(null);
    setItemNotes(null);
    setSubTotal(0);
    setOldSubTotal(0);
    setDiscountedTotal(0);
    setDiscountPercentage(0);
    setDiscountValue(null);
    setdiscountReason("");
    setCashPaidAmount(0);
    setVisaPaidAmount(0);
    setUpdateOrder(false);
    setHall(null);
    setTable(null);
    setOrderStatus(null);
    setOrderMaster(null);
    setCompanies(null);
    setPaymentCompany(null);
    setOrderDetailToModify(null);
  };

  const clearCombineStates = () => {
    setOrderStatus(null);
    setOrderMaster(null);
    setOrderMasterId("");
    setDisableCombineButton(true);
  };

  const clearTotals = () => {
    setSubTotal(0);
    setOldSubTotal(0);
  };
  // end clear functions

  const checkForAddedItems = () => {
    if (addedItems.length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please Add Product(s)!",
        icon: "error",
        confirmButtonText: "Ok",
      });

      return false;
    }
    return true;
  };

  const checkForSelectedHallAndTable = () => {
    if (orderType.id === 1) {
      if (!hall) {
        Swal.fire({
          title: "Error!",
          text: "Please choose hall!",
          icon: "error",
          confirmButtonText: "Ok",
        });

        return false;
      }

      if (!table) {
        Swal.fire({
          title: "Error!",
          text: "Please choose table!",
          icon: "error",
          confirmButtonText: "Ok",
        });

        return false;
      }

      return true;
    }
  };

  // render functions
  const renderAddedItems = () => {
    return addedItems ? (
      addedItems.map((item, index) => (
        <tr key={index} className={item.Void || item.returrn ? "disabled" : ""}>
          <td className="tdata orderid">{index + 1}</td>
          <td className="tdata">{item.itemNameLang2 ?? item.ItemName}</td>
          <td className="tdata">{item.Notes ? item.Notes.toString() : ""}</td>
          <td className="tdata">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item.quantity}

                <input
                  type="text"
                  className="form-control returnedQuantity white-input d-none"
                  placeholder="quantity to return"
                  autoFocus={true}
                  min={0}
                  value={returnedQuantity}
                  onChange={(e) => handleReturnQuantityChange(e, item)}
                />
              </div>
              <div>
                {item.ReturnedQuantity ? (
                  <span style={{ fontSize: "13px" }}>
                    returned qty {item.ReturnedQuantity}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </td>
          <td className="tdata">{item.price}</td>
          <td className="tdata">{item.price * item.quantity}</td>
          <td className="tdata">
            {jobCategory === "جرسون"
              ? renderWaiterActions(item)
              : renderNonWaiterActions(item)}
          </td>
        </tr>
      ))
    ) : (
      <tr></tr>
    );
  };

  const renderCashier = () => {
    return (
      <Fragment>
        {showTakeaway ? (
          <Takeaway
            toggleTakeaway={toggleTakeaway}
            showTakeaway={showTakeaway}
          />
        ) : (
          ""
        )}

        {showDinein ? (
          <Dinein
            toggleDinein={toggleDinein}
            payAndPrintForDineinOrder={payAndPrintForDineinOrder}
            showOrderDetails={showOrderDetails}
            onlyPrintForDineinOrder={onlyPrintForDineinOrder}
          />
        ) : (
          ""
        )}

        {/* {showWaiterFill ? (
          <WaiterFill
            toggleWaiterFill={toggleWaiterFill}
            payAndPrintForDineinOrder={payAndPrintForDineinOrder}
            showOrderDetails={showOrderDetails}
            onlyPrintForDineinOrder={onlyPrintForDineinOrder}
            employee={employee}
            waiterfillOrdersCount={waiterfillOrdersCount}
            updateWaiterFillOrdersCount={setWaiterfillOrdersCount}
            dineinOrdersCount={dineinOrdersCount}
            updateDineinOrdersCount={setDineinOrdersCount}
            orderStatus={orderStatus}
          />
        ) : (
          ""
        )} */}

        {showItems ? (
          <Items
            toggleItems={toggleItems}
            products={items}
            setItemNotes={setItemNotes}
            setSelectedNotes={setSelectedNotes}
            handleSelectProduct={handleSelectProduct}
          />
        ) : (
          ""
        )}
        {showModal ? renderHallTableWaiterModal() : ""}
        {showPaymentModal ? renderPaymentModal() : ""}
        {showReturnModal ? renderReturnModal() : ""}
        <div className="main-wrapper">
          <div className="cashier-wrapper">
            <div className="logo-adds-wrapper">
              <div className="logo-wrapper">
                <img src={logo} alt="logo" />
              </div>
              <div className="adds-wrapper">
                <button>Addition Items</button>
                <div className="general-notes-wrapper">
                  <p className="general-notes-title">General Notes</p>
                  <div className="general-notes-container">
                    {itemNotes
                      ? itemNotes.map((item) => (
                          <span
                            className="general-note"
                            key={item.id}
                            onClick={() => handleSelectNote(item.note)}
                          >
                            {item.note}
                          </span>
                        ))
                      : ""}
                  </div>
                </div>
                {/* <div className="special-notes-wrapper">
              <p className="special-notes-title">Special Notes</p>
              <div className="special-notes-container">
                <span className="special-note">More Salt</span>
                <span className="special-note">Less Salt</span>
                <span className="special-note">Extra Cheese</span>
                <span className="special-note">Chicken</span>
                <span className="special-note">Spicy</span>
              </div>
            </div> */}
              </div>
            </div>
            <div className="main-cashier-wrapper">
              <div className="new-item-wrapper">
                <div className="new-item-table">
                  <table>
                    <thead>
                      <tr>
                        <th className="thead itemname">Item Name</th>
                        <th className="thead itemnotes">Notes</th>
                        <th className="thead">Quantity</th>
                        <th className="thead">Price/Unit</th>
                        <th className="thead">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProduct ? (
                        <tr>
                          <td className="tdata itemname">
                            {selectedProduct.itemNameLang2 ??
                              selectedProduct.ItemName}
                          </td>
                          <td className="tdata itemnotes">
                            {selectedNotes ? selectedNotes.toString() : ""}
                          </td>
                          <td className="tdata itemqty">
                            <input
                              type="number"
                              min={1}
                              id="quantity"
                              name="quantity"
                              value={quantity}
                              autoFocus={true}
                              ref={quantityRef}
                              onChange={(e) => setQuantity(e.target.value)}
                              className="form-control quantity"
                            />
                          </td>
                          <td className="tdata itemprice">
                            {selectedProduct.price}
                          </td>
                          <td className="tdata itemtotal">
                            {selectedProduct.total}
                          </td>
                        </tr>
                      ) : (
                        <tr></tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="newitem-btn-container">
                  <button
                    className="newitem-btn"
                    onClick={handleAddSelectedItem}
                  >
                    Add Item
                  </button>
                  <button
                    className="deletenewitem-btn"
                    onClick={handleRemoveSelectedItem}
                  >
                    Remove
                  </button>
                </div>
              </div>
              <div className="order-items-wrapper">
                <div className="order-items-table">
                  <table>
                    <thead>
                      <tr>
                        <th className="thead">No</th>
                        <th className="thead">Item Name</th>
                        <th className="thead">Notes</th>
                        <th className="thead">Quantity</th>
                        <th className="thead">Price</th>
                        <th className="thead">Total Price</th>
                        <th className="thead">Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderAddedItems()}</tbody>
                  </table>
                </div>
              </div>
              <div className="order-total-wrapper">
                <div className="inputs-wrapper">
                  <div className="total-input-wrapper">
                    <span>Total</span>
                    <input
                      type="text"
                      readOnly
                      placeholder="Auto Fill"
                      className="form-control"
                      value={subtotal}
                    />
                  </div>
                  <div className="addedtax-input-wrapper">
                    <span>Added Tax</span>
                    <input
                      type="text"
                      readOnly
                      placeholder="Auto Fill"
                      className="form-control"
                      value="0"
                    />
                  </div>
                  <div className="totalaftertax-input-wrapper">
                    <span>Total After Tax</span>
                    <input
                      type="text"
                      readOnly
                      placeholder="Auto Fill"
                      className="form-control"
                      value={subtotal}
                    />
                  </div>
                </div>
                {renderMainPaymentButtons()}
              </div>
              <div className="options-calculator-wrapper">
                <div className="dinein-takeaway-wrapper">
                  <div
                    className={`dinein-count-wrapper ${
                      showModal ? "active" : ""
                    }`}
                  >
                    {jobCategory === "جرسون" ? (
                      <span
                        className="dinein-title"
                        onClick={() => setShowModal(true)}
                      >
                        Dine in
                      </span>
                    ) : (
                      <span className="dinein-title text-disabled">
                        Dine in
                      </span>
                    )}

                    <span
                      className="dinein-count"
                      onClick={() => setShowDinein(!showDinein)}
                    >
                      {dineinOrdersCount}
                    </span>
                  </div>
                  {jobCategory === "جرسون" ? (
                    <Fragment>
                      <div
                        className={`dinein-count-wrapper ${
                          showModal ? "active" : ""
                        }`}
                      >
                        <span className="dinein-title">Fine Dine</span>
                        <span
                          className="dinein-count"
                          onClick={() => setShowWaiterFill(!showWaiterFill)}
                        >
                          {waiterfillOrdersCount}
                        </span>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}

                  {jobCategory !== "جرسون" ? (
                    <div
                      className={`takeaway-count-wrapper ${
                        showTakeaway ? "active" : ""
                      }`}
                    >
                      <span
                        className="takeaway-title"
                        onClick={() =>
                          setOrderType({ id: 2, type: "takeaway" })
                        }
                      >
                        Take Away
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <Calculator
                  handleQuantityOrSearchTermChange={
                    handleQuantityOrSearchTermChange
                  }
                  handleDeleteNumber={handleDeleteNumber}
                  searchForOrder={searchForOrder}
                  searchTerm={searchTerm}
                />
                <div className="orderinfo-wrapper">
                  {jobCategory === "جرسون" ? (
                    <div className="info-container">
                      <p className="ordertype-number">
                        <span className="ordertype">
                          Order NO.{" "}
                          {orderMaster ? orderMaster.PK_OrderMaster_ID : ""}
                        </span>
                      </p>
                      <p className="orderroom-table">
                        <span className="ordertype">Hall {hall?.hallName}</span>
                        <span>-</span>
                        <span className="ordertype">
                          Table {table?.tableName}
                        </span>
                      </p>
                      <p className="orderwaiter">
                        <span className="ordertype">Waiter</span>
                        <span>-</span>
                        <span className="ordertype">{waiter?.waiterName}</span>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {orderMaster !== null && jobCategory !== "جرسون" ? (
                    <div className="buttons-wrapper">
                      {jobCategory === "كاشير" ? (
                        <button
                          onClick={(e) => {
                            handleCheckReturnOrder();
                          }}
                        >
                          Return
                        </button>
                      ) : (
                        ""
                      )}

                      <button>Spending</button>
                      <button>Buy Bill</button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="user-notifs-categories-wrapper">
              <div className="user-notifs-wrapper">
                <div className="bell-wrapper">
                  <NotificationsIcon
                    style={{ color: "#333", fontSize: "2rem" }}
                  />
                </div>
                <div className="user-wrapper" onClick={toggleLinksList}>
                  <img src={userImage} className="userimage" alt="user" />
                  <div className="username-wrapper">
                    <span className="username">{employee.empName}</span>
                    <span className="usertype">{jobCategory}</span>
                  </div>
                  <ul
                    className="links-list"
                    ref={linksListRef}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <li onClick={logout}>Logout</li>
                  </ul>
                </div>
              </div>
              <div className="search-wrapper">
                <div className="search-container">
                  <SearchIcon
                    style={{ cursor: "pointer" }}
                    onClick={toggleSearchInput}
                  />
                  <input
                    type="text"
                    className="form-control white-input"
                    name="search"
                    value={searchTerm}
                    ref={SearchOrderInputRef}
                    placeholder="Enter Order Number"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setCombineOrderNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
              <Categories token={token} fetchItems={fetchItems} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderWaiterValidButtonsOptions = () => {
    if (orderMaster && orderStatus === "toUpdate") {
      return (
        <Fragment>
          <button className="paybtn" onClick={saveOrder} disabled={isLoading}>
            {isLoading ? <Spinner /> : <span>Submit</span>}
          </button>

          <button
            className="paybtn"
            onClick={() => {
              setOrderStatus("toPrepare");
              clearStates();
            }}
          >
            Back
          </button>
        </Fragment>
      );
    }

    if (orderMaster && orderMaster.Payed === 0) {
      return (
        <Fragment>
          <button
            disabled={disableCombineButton}
            className={`paybtn ${disableCombineButton ? "disabled-btn" : ""}`}
            onClick={combineOrders}
          >
            Combine
          </button>
          <div className="combineorderid-input-wrapper">
            <span>Combine To Order</span>
            <input
              type="text"
              placeholder="Main Order Id"
              className="form-control white-input"
              ref={SearchCombineOrderInputRef}
              value={orderMasterId}
              autoFocus={true}
              onChange={(e) => {
                setOrderMasterId(e.target.value);
              }}
            />
          </div>
        </Fragment>
      );
    }
    // {
    //   orderMaster.Payed === 0 ? (
    //     <Fragment>
    //       <button
    //         disabled={disableCombineButton}
    //         className={`paybtn ${disableCombineButton ? "disabled-btn" : ""}`}
    //         onClick={combineOrders}
    //       >
    //         Combine
    //       </button>
    //       <div className="combineorderid-input-wrapper">
    //         <span>Combine To Order</span>
    //         <input
    //           type="text"
    //           placeholder="Main Order Id"
    //           className="form-control white-input"
    //           ref={SearchCombineOrderInputRef}
    //           value={orderMasterId}
    //           autoFocus={true}
    //           onChange={(e) => {
    //             setOrderMasterId(e.target.value);
    //           }}
    //         />
    //       </div>
    //     </Fragment>
    //   ) : (
    //     ""
    //   );
    // }

    if (orderStatus === "toPrepare" || orderStatus === "toUpdate") {
      return (
        // <button className="paybtn" onClick={saveOrder}>
        //   Save
        // </button>
        <button className="paybtn" onClick={saveOrder} disabled={isLoading}>
          {isLoading ? <Spinner /> : <span>Save</span>}
        </button>
      );
    }
    // else {
    //   return (
    //     // <button className="paybtn" onClick={saveOrder}>
    //     //   Save
    //     // </button>
    //     <button className="paybtn" onClick={saveOrder} disabled={isLoading}>
    //       {isLoading ? <Spinner /> : <span>Save</span>}
    //     </button>
    //   );
    // }
    // return orderStatus === "toModify" ? (
    //   <Fragment>
    //     <button className="paybtn" onClick={saveOrder}>
    //       Submit
    //     </button>

    //     <button
    //       className="paybtn"
    //       onClick={() => {
    //         setOrderStatus("toPrepare");
    //         clearStates();
    //       }}
    //     >
    //       Back
    //     </button>
    //   </Fragment>
    // ) : (
    //   <button className="paybtn" onClick={saveOrder}>
    //     Save
    //   </button>
    // );
  };

  const renderNonWaiterValidButtonsOptions = () => {
    if (orderStatus === "toModify" || orderStatus === "toUpdate") {
      return (
        <Fragment>
          <button className="paybtn" onClick={saveOrder} disabled={isLoading}>
            {isLoading ? <Spinner /> : <span>Submit</span>}
          </button>

          <button
            className="paybtn"
            onClick={() => {
              setOrderStatus("toPrepare");
              clearStates();
            }}
          >
            Back
          </button>
        </Fragment>
      );
    }

    if (orderStatus === "toPrepare") {
      return (
        <button className="paybtn" onClick={payAndPrint}>
          {isLoading ? <Spinner /> : <span> Save & Pay</span>}
        </button>
      );
    }

    // return (
    //   <button className="paybtn" onClick={payAndPrint}>
    //     {isLoading ? <Spinner /> : <span> Save & Pay</span>}
    //   </button>
    // );

    // else if (orderType && orderType.id === 1) {
    //   return (
    //     <button className="paybtn" onClick={payAndPrint}>
    //       {isLoading ? <Spinner /> : <span> Save & Pay</span>}
    //     </button>
    //   );
    // } else {
    //   return (
    //     <button className="paybtn" onClick={payAndPrint}>
    //       {isLoading ? <Spinner /> : <span> Save & Pay</span>}
    //     </button>
    //   );
    // }
  };

  const renderMainPaymentButtons = () => {
    return orderMaster && orderMaster.fkOrderTypeId === 2 ? (
      ""
    ) : (
      <div className="paybtn-wrapper">
        {jobCategory === "جرسون"
          ? renderWaiterValidButtonsOptions()
          : renderNonWaiterValidButtonsOptions()}
      </div>
    );
  };
  // end render functions

  return <Fragment>{token ? renderCashier() : ""}</Fragment>;
};

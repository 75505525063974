import React from "react";
import { Main } from "./Main";
import "../assets/styles/addarea.css";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const AddArea = () => {
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#FFEDC9",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: "#FFEDC9 !important",
            },
          },
        },
        MuiTableBody: {
          styleOverrides: {
            root: {
              // height: "100%",
              zIndex: "1000",
              position: "relative",
              backgroundColor: "#FFEDC9 !important",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              backgroundColor: "#FFEDC9",
            },
          },
        },
      },
    });

  const toggleActions = (e) => {
    const actionsIcon = e.target;
    const targetActionsIconWrapper = actionsIcon.closest(
      ".actions-icon-wrapper"
    );
    const actionsWrapper =
      targetActionsIconWrapper.querySelector(".actions-wrapper");
    actionsWrapper.classList.toggle("show");
    hideAllActionsWrappers(actionsWrapper);
  };

  const hideAllActionsWrappers = (targetActionsWrapper) => {
    let actionsWrapper = Array.from(
      document.querySelectorAll(".actions-wrapper")
    );
    actionsWrapper = actionsWrapper.filter((item) => {
      return item !== targetActionsWrapper;
    });
    actionsWrapper.forEach((item) => {
      item.classList.remove("show");
    });
  };
  const columns = [
    { name: "area name", label: "Area Name" },
    {
      name: "area description",
      label: "Area Description",
    },
    { name: "number of staff", label: "Number Of Staff" },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="actions-icon-wrapper">
              <div className="actions-wrapper">
                <ul>
                  <li>Show</li>
                  <li>Edit</li>
                  <li>Delete</li>
                </ul>
              </div>
              <MoreVertIcon
                onClick={(e) => toggleActions(e)}
                style={{ cursor: "pointer" }}
              />
            </div>
          );
        },
      },
    },
  ];

  const data = [
    ["Joe James", "Test Corp", "Yonkers"],
    ["John Walsh", "Test Corp", "Hartford"],
    ["Bob Herm", "Test Corp", "Tampa"],
    ["James Houston", "Test Corp", "Dallas"],
  ];

  const options = {
    filterType: "checkbox",
    rowsPerPageOptions: [10, 20, 100],
  };

  return (
    <Main>
      <div className="add-area-wrapper">
        <form className="form">
          <div className="form-group">
            <label htmlFor="name">Area Name</label>
            <input
              className="form-control"
              name="name"
              type="text"
              placeholder="Enter area name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              className="form-control"
              rows="5"
              name="description"
              placeholder="Enter description"
            ></textarea>
          </div>
          <button className="save-area">Save</button>
        </form>
      </div>
      <div className="areas-table-wrapper">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={"Areas Table"}
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </Main>
  );
};

import { useEffect } from "react";
import { Modal } from "./Modal";

export const ReturnModal = ({
  closeModal,
  customStyle,
  subtotal,
  showReturnModal,
  setShowReturnModal,
  visaSafes,
  orderMaster,
  setReturnMethod,
  returnMethod,
  setReturnVisaSafe,
  handleReturnOrder,
  orderDetailToModify,
}) => {
  useEffect(() => {
    setReturnMethod("cash");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal closeModal={closeModal} customStyle={customStyle}>
      <div className="payment-methods-wrapper">
        <p>Order Total: {subtotal}</p>
        <p>Order Disount: {orderMaster.Discount}</p>
        <p>Order After Discount: {orderMaster.Payed}</p>
        <p>
          Order Payment Method:{" "}
          {orderMaster.IsCash
            ? "cash"
            : orderMaster.IsVisa
            ? "Visa"
            : orderMaster.IsMix
            ? "Mix"
            : orderMaster.IsCompany
            ? "Company"
            : "Not Payed"}
        </p>

        <strong>Return By</strong>

        <div className="methodswrapper">
          <div className="paymentmethod">
            <input
              type="radio"
              id="cash"
              name="paymentmethod"
              checked={returnMethod === "cash"}
              onChange={(e) => {
                setReturnMethod("cash");
              }}
            />
            <label htmlFor="cash">Cash</label>
          </div>
          <div className="paymentmethod">
            <input
              type="radio"
              id="visa"
              name="paymentmethod"
              checked={returnMethod === "visa"}
              onChange={(e) => {
                setReturnMethod("visa");
              }}
            />
            <label htmlFor="visa">Visa</label>
          </div>

          <div className="paymentmethod">
            <input
              type="radio"
              id="company"
              name="paymentmethod"
              checked={returnMethod === "company"}
              onChange={(e) => {
                setReturnMethod("company");
              }}
            />
            <label htmlFor="mix">Company</label>
          </div>
        </div>

        {/* <div className="row" style={{ marginBottom: "1rem" }}>
          <div className="col-md-4">
            <label htmlFor="discount">Dicsount</label>
            <select
              name="discount"
              onChange={(e) => handleDiscountChange(e)}
              className="form-control"
              id="discount"
              defaultValue={currentSelectedOption}
              style={{
                backgroundColor: "#fff",
                border: "1px solid grey",
                color: "black",
              }}
            >
              {discountsList
                ? discountsList.map((item, index) => (
                    <option
                      disabled={item.pkDiscountId === 0}
                      // selected={item.pkDiscountId === 0}
                      key={index}
                      value={item.discountPercentage}
                    >
                      {item.discountPercentage}
                    </option>
                  ))
                : ""}
            </select>
          </div>
        </div> */}

        {visaSafes ? (
          <div className="col-md-4 mb-3">
            <p>Safes</p>
            <select
              name="company"
              id="company"
              style={{
                backgroundColor: "#fff",
                border: "1px solid grey",
                color: "black",
              }}
              className="form-control"
              onChange={(e) => setReturnVisaSafe(parseInt(e.target.value))}
            >
              {visaSafes
                ? visaSafes.map((item, index) => (
                    <option key={index} value={item.pkSafeId}>
                      {item.safeName}
                    </option>
                  ))
                : ""}
            </select>
          </div>
        ) : (
          ""
        )}

        {/* {paymentMethod === "mix" ? renderMixPaymentInputs() : ""} */}

        <div className="row paymentModalBtnsWrapper">
          <div className="col-md-6">
            <button
              onClick={() => {
                orderDetailToModify ? closeModal() : handleReturnOrder();
              }}
            >
              Ok
            </button>
          </div>
          <div className="col-md-6">
            <button
              onClick={(e) => {
                setShowReturnModal(!showReturnModal);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

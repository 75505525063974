import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { SidebarItem } from "./SidebarItem";
import navbarlogo from "../../assets/images/logo.png";

export const Sidebar = () => {
  console.log("render");
  // const clickMe = (e) => {
  //   const sidebarLinks = document.querySelectorAll(".sidebar-link");
  //   sidebarLinks.forEach((item) => {
  //     console.log("item");
  //     item.classList.remove("arrow-right");
  //   });

  //   const targetItem = e.target.closest(".sidebar-link");
  //   console.log(targetItem);
  //   targetItem.classList.add("arrow-right");
  // };
  return (
    <div className="nav-container">
      <div className="mobile-topbar-header">
        <div>
          <img src={navbarlogo} className="logo-icon" alt="logo icon" />
        </div>
        <div>
          <h4 className="logo-text">Dashtreme</h4>
        </div>
        <div className="toggle-icon ms-auto">
          <i className="bx bx-arrow-to-left"></i>
        </div>
      </div>
      <nav className="topbar-nav">
        <ul className="metismenu" id="menu">
          <SidebarItem path="/" title="Dashboard" icon="fa-solid fa-gauge" />
          <SidebarItem
            path="/add-area"
            to="/add-area"
            title="Add Adrea"
            icon="fa-solid fa-gauge"
          />
          <SidebarItem
            path="/list-items"
            to="/list-items"
            title="List Items"
            icon="fa-solid fa-gauge"
          />
          <SidebarItem
            path="/add-items"
            title="Add Items"
            icon="fa-solid fa-gauge"
            to="/add-items"
          />
          <SidebarItem
            path="/cashier"
            title="Cashier"
            icon="fa-solid fa-gauge"
          />
          <SidebarItem
            path="/captainorder"
            title="Captain Order"
            icon="fa-solid fa-gauge"
          />
          <SidebarItem
            path="/kitchen"
            title="Kitchen"
            icon="fa-solid fa-gauge"
          />
          {/* <SidebarItem
            hash="#/"
            hasArrow="has-arrow"
            title="My Levels"
            icon="fa-solid fa-arrows-split-up-and-left"
          >
            <ul className="mm-collapse sidebarlink-list">
              <li>
                <Link to="/add-level">
                  <i className="bx bx-right-arrow-alt"></i>Add Level
                </Link>
              </li>
              <li>
                <Link to="/arrange-levels">
                  <i className="bx bx-right-arrow-alt"></i>Arrange Levels
                </Link>
              </li>
              <li>
                <Link to="/levels-table">
                  <i className="bx bx-right-arrow-alt"></i>Levels view
                </Link>
              </li>
            </ul>
          </SidebarItem> */}
          {/* 
          <SidebarItem
            path="/add-record"
            title="Add New"
            icon="fa-solid fa-circle-plus"
          />
          <SidebarItem
            path="/manage-your-location"
            title="Location Management"
            icon="fa-solid fa-map-location"
          />
          <SidebarItem
            path="/location-details"
            title="Location Details"
            icon="fa-solid fa-map-location"
          /> */}
        </ul>
      </nav>
    </div>
  );
};

import React, { useState } from "react";
import navbarlogo from "../../assets/images/logo.png";
// import ThemeContext from "../../shop/ThemeContext";
import avatar from "../../assets/images/avatars/avatar-1.png";

export const Header = () => {
  const [showUserList, setShowUserList] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  // const ctx = useContext(ThemeContext);
  // const storageTheme = localStorage.getItem("theme");

  return (
    <header>
      <div className={`topbar d-flex align-items-center`}>
        <nav className="navbar navbar-expand">
          <div className="topbar-logo-header">
            <div className="">
              <img src={navbarlogo} className="logo-icon" alt="logo icon" />
            </div>
          </div>
          <div className="mobile-toggle-menu">
            <i className="bx bx-menu"></i>
          </div>
          <div className="search-bar flex-grow-1">
            <div className="position-relative search-bar-box">
              <input
                type="text"
                className="form-control search-control"
                placeholder="Type to search..."
              />
              <span className="position-absolute top-50 search-show translate-middle-y">
                <i className="bx bx-search"></i>
              </span>
              <span className="position-absolute top-50 search-close translate-middle-y">
                <i className="bx bx-x"></i>
              </span>
            </div>
          </div>
          <div className="top-menu ms-auto">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item mobile-search-icon">
                <a className="nav-link" href="#/">
                  <i className="bx bx-search"></i>
                </a>
              </li>

              <li className="nav-item dropdown dropdown-large">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative"
                  href="#/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    setShowNotifications(!showNotifications);
                  }}
                >
                  <span className="alert-count">7</span>
                  <i className="bx bx-bell"></i>
                </a>
                <div
                  className={`dropdown-menu dropdown-menu-end ${
                    showNotifications ? "show" : ""
                  }`}
                >
                  <a href="#/">
                    <div className="msg-header">
                      <p className="msg-header-title">Notifications</p>
                      <p className="msg-header-clear ms-auto">
                        Marks all as read
                      </p>
                    </div>
                  </a>
                  <div className="header-notifications-list">
                    <a className="dropdown-item" href="#/">
                      <div className="d-flex align-items-center">
                        <div className="notify">
                          <i className="bx bx-group"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Customers
                            <span className="msg-time float-end">
                              14 Sec ago
                            </span>
                          </h6>
                          <p className="msg-info">5 new user registered</p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="#/">
                      <div className="d-flex align-items-center">
                        <div className="notify">
                          <i className="bx bx-cart-alt"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Orders
                            <span className="msg-time float-end">
                              2 min ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            You have recived new orders
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="#/">
                      <div className="d-flex align-items-center">
                        <div className="notify">
                          <i className="bx bx-file"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            24 PDF File
                            <span className="msg-time float-end">
                              19 min ago
                            </span>
                          </h6>
                          <p className="msg-info">The pdf files generated</p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="#/">
                      <div className="d-flex align-items-center">
                        <div className="notify">
                          <i className="bx bx-send"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            Time Response
                            <span className="msg-time float-end">
                              28 min ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            5.1 min avarage time response
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="#/">
                      <div className="d-flex align-items-center">
                        <div className="notify">
                          <i className="bx bx-home-circle"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Product Approved
                            <span className="msg-time float-end">
                              2 hrs ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            Your new product has approved
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="#/">
                      <div className="d-flex align-items-center">
                        <div className="notify">
                          <i className="bx bx-message-detail"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New Comments
                            <span className="msg-time float-end">
                              4 hrs ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            New customer comments recived
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="#/">
                      <div className="d-flex align-items-center">
                        <div className="notify">
                          <i className="bx bx-check-square"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            Your item is shipped
                            <span className="msg-time float-end">
                              5 hrs ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            Successfully shipped your item
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="#/">
                      <div className="d-flex align-items-center">
                        <div className="notify">
                          <i className="bx bx-user-pin"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            New 24 authors
                            <span className="msg-time float-end">
                              1 day ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            24 new authors joined last week
                          </p>
                        </div>
                      </div>
                    </a>
                    <a className="dropdown-item" href="#/">
                      <div className="d-flex align-items-center">
                        <div className="notify">
                          <i className="bx bx-door-open"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="msg-name">
                            Defense Alerts
                            <span className="msg-time float-end">
                              2 weeks ago
                            </span>
                          </h6>
                          <p className="msg-info">
                            45% less alerts last 4 weeks
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <a href="#/">
                    <div className="text-center msg-footer">
                      View All Notifications
                    </div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="user-box dropdown">
            <a
              className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret"
              href="#/"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => {
                setShowUserList(!showUserList);
              }}
            >
              <img src={avatar} className="user-img" alt="user avatar" />
              <div className="user-info ps-3">
                <p className="user-name mb-0">Pauline Seitz</p>
                <p className="designattion mb-0">Web Designer</p>
              </div>
            </a>
            <ul
              className={`dropdown-menu dropdown-menu-end ${
                showUserList ? "show" : ""
              }`}
            >
              <li>
                <a className="dropdown-item" href="#/">
                  <i className="bx bx-user"></i>
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#/">
                  <i className="bx bx-cog"></i>
                  <span>Settings</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#/">
                  <i className="bx bx-home-circle"></i>
                  <span>Dashboard</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#/">
                  <i className="bx bx-dollar-circle"></i>
                  <span>Earnings</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#/">
                  <i className="bx bx-download"></i>
                  <span>Downloads</span>
                </a>
              </li>
              <li>
                <div className="dropdown-divider mb-0"></div>
              </li>
              <li>
                <a className="dropdown-item" href="#/">
                  <i className="bx bx-log-out-circle"></i>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

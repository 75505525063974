import React, { Fragment, useEffect, useState } from "react";
import axiosAuthInstance from "../../api/axiosAuthInstance";
import "../../assets/styles/takeaway.css";
import XMLParser from "react-xml-parser";

export const Dinein = ({
  toggleDinein,
  payAndPrintForDineinOrder,
  showOrderDetails,
  onlyPrintForDineinOrder,
}) => {
  const [dineinOrders, setDineinOrders] = useState(null);
  const [modifiedTooltip, setModifiedTooltip] = useState(null);

  useEffect(() => {
    const fetchDineinOrders = async () => {
      const response = await axiosAuthInstance(`Cashier/getDinInOrders`);

      setDineinOrders(response.data);
    };
    try {
      fetchDineinOrders();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (dineinOrders !== null && dineinOrders.length > 0) {
      let modifiedDineinOrdersXml = [];
      dineinOrders.forEach((item, index) => {
        let itemObject = { orderNo: item.Order_No, formattedTooltip: [] };
        const xml = dineinOrders[index].OrderItemsToolTip
          ? new XMLParser().parseFromString(
              dineinOrders[index].OrderItemsToolTip
            )
          : null;
        if (xml) {
          xml.getElementsByTagName("Item").forEach((xmlItem) => {
            let modifiedChild = {
              itemId: item.Order_No,
              name: xmlItem.children[0]["value"],
              quantity: xmlItem.children[1]["value"],
            };
            itemObject["formattedTooltip"].push(modifiedChild);
          });
        }

        modifiedDineinOrdersXml.push(itemObject);
      });

      setModifiedTooltip(modifiedDineinOrdersXml);
    }
  }, [dineinOrders]);

  const toggleShowDinein = () => {
    document.querySelector(".dinein-container").classList.toggle("show");
    setTimeout(() => {
      toggleDinein();
    }, 300);
  };
  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".dinein-container").classList.add("show");
    }, 0);
  }, []);

  const jobCategory = sessionStorage.getItem("jobCategory");

  const renderOrderToolTip = (order_no) => {
    let modifiedXml;
    modifiedXml = modifiedTooltip?.find((item, index) => {
      return order_no === item["orderNo"];
    });
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {modifiedXml ? (
            modifiedXml?.formattedTooltip?.map((item, index) => (
              <tr key={index}>
                <td>{item["name"]}</td>
                <td>{item["quantity"]}</td>
              </tr>
            ))
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div style={{}}>
      <div
        className="ta-wrapper"
        onClick={() => {
          toggleShowDinein();
        }}
      ></div>
      <div className={`dinein-container`} onClick={(e) => e.stopPropagation()}>
        <table className="ta-table dinein-orders-table">
          <thead>
            <tr>
              <th className="">Order-No</th>
              <th className="">Hall</th>
              <th className="">Table</th>
              <th className="">Waiter</th>
              <th className="ta-ordertitle">Order</th>
              <th className="">Action</th>
            </tr>
          </thead>
          <tbody>
            {dineinOrders ? (
              dineinOrders.map((item) => (
                <tr key={item.Order_No}>
                  <td className="">{item.Order_No}</td>
                  <td className="">{item.HallName}</td>
                  <td className="">{item.TableName}</td>
                  <td className="ta-waitername">{item.Waiter}</td>

                  <td className="ta-itemname">
                    <p>{item.OrderItems}</p>
                    <div className="order-tooltip">
                      {renderOrderToolTip(item.Order_No)}
                    </div>
                  </td>
                  <td className="">
                    {jobCategory !== "جرسون" ? (
                      <Fragment>
                        <button
                          onClick={() => {
                            onlyPrintForDineinOrder(item.Order_No);
                          }}
                        >
                          Only Print
                        </button>
                        <button
                          onClick={() =>
                            payAndPrintForDineinOrder(item.Order_No)
                          }
                        >
                          Pay & Print
                        </button>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <button
                          onClick={() => {
                            showOrderDetails(
                              item.Order_No,
                              item.FK_HallID,
                              item.HallName,
                              item.FK_Table_ID,
                              item.TableName,
                              item.FK_WaiterID,
                              item.Waiter
                            );
                          }}
                        >
                          Add Item
                        </button>
                        <button
                          onClick={() => {
                            onlyPrintForDineinOrder(item.Order_No);
                          }}
                        >
                          Only Print
                        </button>
                      </Fragment>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

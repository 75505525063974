import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AddArea } from "../components/AddArea";
import App from "../components/App";
import { Login } from "../components/auth/Login";
import { PasswordReset } from "../components/auth/PasswordReset";
import { CaptainOrderArea } from "../components/CaptainOrderArea";
import { Cashier } from "../components/cashier/Cashier";
import { Orders } from "../components/cashier/Orders";
import { ListItems } from "../components/ListItems";
import "../assets/styles/all.css";
import { Kitchen } from "../components/Kitchen";
import { AddItems } from "../components/AddItems";

export const AppRoutes = () => {
  const userToken = sessionStorage.getItem("token");

  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            exact
            element={
              userToken ? <Navigate to="/cashier" /> : <Navigate to="/login" />
            }
          />
          <Route path="/add-area" element={<AddArea />} />
          <Route path="/list-items" element={<ListItems />} />
          <Route path="/add-items" element={<AddItems />} />
          <Route path="/cashier" element={<Cashier />} />
          <Route path="/captainorder" element={<CaptainOrderArea />} />
          <Route path="/kitchen" element={<Kitchen />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-your-password" element={<PasswordReset />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};
